// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import Section from '../molecules/Section';
import TextBlock from '../atoms/TextBlock';

type Props = {
  title: String,
  description: String,
  items: {
    header: String,
    bodies: String[],
  }[],
};

const PurchaseDetails = ({ title, description, items }: Props) => {
  const theme = useTheme();

  return (
    <Section title={title}>
      <TextBlock text={description} align="center" color={theme.colors.primary} />
      <table>
        <tbody>
          {items.map((item, index) => (
            <tr key={index.toString()}>
              <HeadCell>{item.header}</HeadCell>
              <Cell>
                <List>
                  {item.bodies.map((body, index2) =>
                    body !== null ? (
                      <li key={index2.toString()}>{body}</li>
                    ) : (
                      <Margin key={index2.toString()} />
                    )
                  )}
                </List>
              </Cell>
            </tr>
          ))}
        </tbody>
      </table>
    </Section>
  );
};

const Cell = styled.td((props) => ({
  border: `1px solid ${props.theme.colors.primary}`,
  padding: '1rem',
}));

const HeadCell = styled(Cell)((props) => ({
  backgroundColor: '#FEF1E4',
  fontWeight: 'normal',
  textAlign: 'center',
  '&:first-of-type': {
    paddingLeft: '1rem',
  },
  [props.theme.mediaQuery.minTablet]: {
    whiteSpace: 'nowrap',
  },
}));

const List = styled.ul({
  listStyle: 'none',
  margin: '0 1rem',
});

const Margin = styled.li({
  height: '1.2rem',
});

export default PurchaseDetails;
