import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Template from '../templates/PurchasePage';

const query = graphql`
  query {
    pageYaml(page: { eq: "purchase" }) {
      hero
      title
      description
      items {
        header
        bodies
      }
    }
  }
`;

const PurchasePage = () => (
  <StaticQuery query={query} render={(data) => <Template pageData={data.pageYaml} />} />
);

export default PurchasePage;
