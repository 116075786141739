// @flow strict
import React from 'react';

import Hero from '../atoms/Hero';
import PurchaseDetails from '../organisms/PurchaseDetails';

type Props = {
  pageData: {
    hero: String,
    title: String,
    description: String,
    items: {
      header: String,
      bodies: String[],
    },
  },
};

const PurchasePage = ({ pageData }: Props) => (
  <div>
    <Hero color={pageData.hero} />
    <PurchaseDetails
      title={pageData.title}
      description={pageData.description}
      items={pageData.items}
    />
  </div>
);

export default PurchasePage;
